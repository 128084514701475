.alert-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
    top: 0;
    left: 0;
}

.custom-alert {
    background-color: #ffffff!important;
}