
.sidebar {
    min-height: 100%;
    padding-right: 0;
    padding-left: 0;
}
.active {
    background-color: #004085!important;
}

.nav {
    padding-right: 0;
}
.sidebar .nav-link {
    color: #fefefe;
}

.sidebar .nav-item {
    border-bottom: 1px solid rgba(255,255,255,.3);
    width: 100%;
}

.sidebar .nav .nav-item:last-child {
    border-bottom: none;
}

.sidebar .nav-link:hover {
    color: #dddddd;
}

.navOpen, .navClose { display: none}

@media screen and (max-width: 767px) {
    .sidebar {
        position: fixed;
        z-index: 10;
        transform: translateX(-100%);
        transition: all .3s ease;
        top: 0;
    }

    .sidebar.open {
        transform: translateX(0%);
    }

    .navOpen, .navClose {
        display: block;
        width: auto;
    }

    .navOpen {
        position: absolute;
        right: 1rem;
        top: 5rem;
    }

    .navClose {
        margin: 1rem;
    }
}